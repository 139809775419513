* {
    box-sizing: border-box;
    color: #fff;
}
.App {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 0;
}
.container {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background: #2a2a2a;
    display: flex;
    flex-direction: column;
    z-index: -1;
    overflow: hidden;
}

a {
    color: #fff;
}

/* gsapでpinを使用したときに生成される要素のクラス
.pin-spacer {
    max-height: 100vh !important;
}*/

/* .frame{
  display: flex;f
  flex-direction: column;
} */

/* Load Component */

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #2a2a2a;
    z-index: 9999;
    display: flex;
    align-items: center;
    position: relative;
}

.loaded {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #2a2a2a;
    z-index: 9999;
    display: flex;
    align-items: center;
    font-size: 24px;
}
.loaded-after {
    visibility: hidden;
}

.loaded-fadeout {
    animation: ld-fadeout 1s ease 0s 1 forwards;
}
.loaded-zindex {
    z-index: 0 !important;
}

@keyframes ld-fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes ld-fadein {
    0% {
        opacity: 0;
        left: -20px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}
@keyframes ld-fadein-blur {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.loaded::after {
    width: 0;
    height: 0;
    display: none;
}

.load-animation {
    width: 100%;
    position: relative;
    font-size: 3vw;
    line-height: 4rem;
    text-align: center;
    height: 0vh;
    display: flex;
    flex-direction: column;
    /*align-items: end;
    text-align: left;*/
}
.load-animation-end {
    display: flex;
    align-items: center;
    justify-content: center;
}
.letter,
.japanese-text {
    font-family: 'Courier New';
    position: relative;
    color: #ffffff;
    /*width: 65%;*/
    padding-right: 20px;
}
.letter {
    display: inline-block;
    font-size: 0.8em;
    line-height: 3rem;
}
.japanese-text {
    word-break: keep-all;
    overflow-wrap: break-word;
    margin-top: 50px;
    line-height: 5vw;
    font-size: 0.8em;
    padding: 0 5vw;
    font-family: serif;
    opacity: 0;
}
.japanese-text-name {
    font-size: 0.8em;
    margin-top: 40px;
    font-style: italic;
    text-align: right;
}
.japanese-text-year {
    text-align: right;
    font-style: italic;
    font-size: 0.6em;
}
.japanese-text-apper {
    animation: ld-fadein 1s ease 0s 1 forwards;
}

.load-img {
    position: fixed;
    top: 0;
    width: 50vw;
    height: 100vh;
    object-fit: cover;
    object-position: center top;
    opacity: 0.4;
}

.japanese-text-disapper {
}

.img-disapper {
    animation: ld-fadeout 1s ease 0s 1 forwards;
}

.japanese-text-disable {
    display: none !important;
}
.img-apper {
    display: block;
    animation: ld-fadein 1s ease 0s 1 forwards;
}
.load-img-wrap {
    position: relative;
    z-index: -1;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    opacity: 0;
}
.progress-bar-container {
    height: 20px;
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 100%;
    height: 4px;
    padding: 0 1%;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50; /* Green color */
    /*animation: progress-animation 58s linear forwards;*/ /* 60秒かけてアニメーション */
}

.progress-text {
    color: #fff;
    font-size: 1em;
    line-height: 20px;
}
/* breadcrumbs */

.breadcrumbs {
    color: #fff;
    height: auto;
    left: 30vh;
    margin-top: 70px;
    position: relative;
    width: calc(100vw - 20vh);
    font-size: 20px;
    background-color: #dddddd55;
    padding: 5px 15px;
}
.breadcrumbs a {
    color: #fff;
}

/* Silebar Component */

.menu {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh;
    aspect-ratio: 1 / 5;
    background: #2a2a2a00;
    z-index: 9998;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    display: flex;
    background-color: #141414;
    max-width: 150px;
}

.logo h1 {
    font-size: 64px;
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
    position: relative;
    text-align: center;
    margin: auto;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    -webkit-background-clip: text;
    background-repeat: no-repeat;
}

.menu ul {
    list-style: none;
    padding-left: 0;
    padding-bottom: 2vh;
}

.menu nav li a {
    display: block;
    margin: 20px 0px;
    text-decoration: none;
    font-family: 'Menlo', 'Monaco', 'Courier New', 'monospace';
    font-size: 1vw;
    color: #ffffff;
    text-align: right;
}
.top-is-active {
    color: #22ff01 !important;
}
.policy-is-active {
    color: #00f7ff !important;
}
.sol-is-active {
    color: #ff018d !important;
}
.member-is-active {
    color: #c3ff00 !important;
}
.recruit-is-active {
    color: #ff0000 !important;
}
.contact-is-active {
    color: #8800ff !important;
}

/* Top Component */
#top {
    height: 300vh;
}
.top {
    top: 0;
    position: relative;
    width: 100vw;
    height: 600vh;
    background: #00000000;
    z-index: 1;
}
.top.filter {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #7f7f7f20;
    z-index: 10;
}
.loop-wrapper {
    position: absolute;
    overflow: hidden;
    width: 115vw;
    height: 30vh;
    color: rgba(255, 255, 255, 0.313);
    z-index: 11;
}
.looptext {
    animation: loop 30s linear infinite;
    width: 240vw;
    z-index: 11;
    font-size: 10vw;
    font-family: 'Hiragino Kaku Gothic ProN';
    margin: 0;
}
.follow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    animation: loop 30s -15s linear infinite;
}

.looptext-sp {
    animation: loop 20s linear infinite;
    width: 240vw;
    z-index: 11;
    font-size: 10vw;
    font-family: 'Hiragino Kaku Gothic ProN';
    margin: 0;
}
.follow-sp {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    animation: loop 20s -10s linear infinite;
}
@keyframes loop {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.loop-wrapper.down {
    top: 70vh;
}
.looptextx {
    animation: loopx 20s linear infinite;
    width: 240vw;
    z-index: 11;
    font-size: 10vw;
    font-family: 'Hiragino Kaku Gothic ProN', sans-serif;
    margin: 0;
}
.followx {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    animation: loopx 20s -10s linear infinite;
}
.looptextx-sp {
    animation: loopx 20s linear infinite;
    width: 240vw;
    z-index: 11;
    font-size: 10vw;
    font-family: 'Hiragino Kaku Gothic ProN', sans-serif;
    margin: 0;
}
.followx-sp {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    animation: loopx 20s -10s linear infinite;
}
@keyframes loopx {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
.top-text > p {
    position: absolute;
    width: 100vw;
    text-align: center;
    vertical-align: center;
    top: 36vh;
    font-size: 3.5vw;
    letter-spacing: 5px;
    font-family: YuMincho, 'YuMincho', serif;
    z-index: 30;
    color: #ffffff;
}
.video-container {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: #000;
}
.fullscreen-video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 48vh;
    width: auto;
    transform: translate(-50%, -50%);
}
#topvideo {
    background-size: cover;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: 9;
}
.wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9998;
    perspective: 600px;
}
.k {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9998;
}

.k-left {
    position: absolute;
}
.k-left-solid {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: auto;
    height: 100vh;
    aspect-ratio: 1 / 5;
    background: #ffffffff;
}
.k-left-border {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh;
    aspect-ratio: 1 / 5;
    background: #00000000;
    border: 1px solid #2a2a2a33;
}

.k-right {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 80vw;
}

.k-right-solid {
    position: absolute;
    background: #ffffffff;
    width: auto;
    height: 100vh;
    aspect-ratio: 1 / 1.25;
    /* left: calc(100vw * 0.35 + ( 100vh * 0.8 ) * ( 0.25 )); */
    clip-path: polygon(50% 0%, 75% 0%, 25% 50%, 75% 100%, 50% 100%, 0% 50%);
    opacity: 0;
}
.background-right {
    position: absolute;
    display: flex;
    flex-direction: row;
    opacity: 0;
    z-index: 9000;
}
.bg-tri {
    position: relative;
    display: flex;
    background-color: #2a2a2a;
    width: auto;
    height: 100vh;
    left: 80vw;
    aspect-ratio: 1 / 1.25;
    z-index: 9990;
    /* left: calc(100vw * 0.35 + ( 100vh * 0.8 ) * ( 0.25 )); */
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 50%);
}
.bg-sq {
    position: relative;
    display: flex;
    background-color: #2a2a2a;
    width: 50vw;
    height: 100vh;
    left: 80vw;
    aspect-ratio: 1 / 1.25;
    z-index: 9990;
    transform: translateX(-1%);
}

.background-left {
    position: absolute;
    background-color: #2a2a2a;
    width: 100vw;
    height: 100.1vh;
    z-index: 9000;
    top: 0;
    left: -100%;
    /* transform: translateX(-100%); */
    opacity: 0;
    z-index: 9990;
}

/* Policy component */
.kite,
.kewl,
.kudos {
    position: fixed;
    z-index: 9998;
    opacity: 0;
    background-color: #2a2a2a90;
    padding: 20px;
    width: 25vw;
}
.kite {
    top: 0;
    left: 66vw;
}
.kewl {
    top: 33vh;
    left: 22vh;
}
.kudos {
    top: 45vh;
    left: 72vw;
}
.path-kite {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.path-kite #svg {
    transform: translate(-20%, 0%);
}
.path {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
h1.policy-head {
    position: relative;
    color: #ffffff;
    font-family: 'Arial Black', sans-serif;
    font-size: 3vw;
    margin-top: 0;
    margin-bottom: 1vh;
}
.policy-text {
    position: relative;
    color: #ffffff;
    margin-top: 0;
    font-size: 1.5vw;
}
.dash {
    position: absolute;
    display: flex;
    margin: 0 auto;
    width: auto;
    left: 0;
    right: 0;
    justify-content: center;
    height: 100vh;
}
.dash1 {
    position: absolute;
    width: auto;
    height: 100vh;
    top: -10vh;
    aspect-ratio: 1 / 1.25;
    clip-path: polygon(50% 0%, 75% 0%, 25% 50%, 75% 100%, 50% 100%, 0% 50%);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    z-index: 9996;
    background: #555555;
    scale: 0.4;
    opacity: 0;
}
.dash2 {
    position: absolute;
    width: auto;
    height: 100vh;
    top: 4vh;
    aspect-ratio: 1 / 1.25;
    clip-path: polygon(50% 0%, 75% 0%, 25% 50%, 75% 100%, 50% 100%, 0% 50%);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    z-index: 9996;
    background: #aaaaaa;
    scale: 0.4;
    opacity: 0;
}
.dash-cover {
    position: absolute;
    width: auto;
    height: 101vh;
    margin: auto;
    top: 4vh;
    right: 0;
    left: 0;
    aspect-ratio: 1 / 1.25;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%, 100% 100%, 0% 100%);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    z-index: 9997;
    background: #2a2a2a;
    scale: 0.4;
    opacity: 0;
}

/* Solution Component */
.solution {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh !important;
    /* background: #2a2a2a; */
    background: #2a2a2a;
    z-index: 1000;
}
.sol-container {
    position: absolute;
    /* width: 100%; */
    height: 100vh;
    left: 20vh;
    right: 0;
    margin-right: 20px;
    display: flex;
    justify-content: space-around;
    z-index: 1100;
}
.sol {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    opacity: 0;
    z-index: 1200;
    top: 0;
    left: 0;
    bottom: 0;
}
.sol1 {
    position: absolute;
    clip-path: polygon(3% 0%, 33% 0%, 33% 90%, 3% 90%);
    transition: 0.6s ease;
}
.sol2 {
    position: absolute;
    clip-path: polygon(36% 10%, 66% 10%, 66% 100%, 36% 100%);
    transition: 0.6s ease;
}
.sol3 {
    clip-path: polygon(69% 0%, 99% 0%, 99% 90%, 69% 90%);
    transition: 0.6s ease;
}
.sol1:hover {
    clip-path: polygon(2% 0%, 34% 0%, 34% 91%, 2% 91%);
}
.sol2:hover {
    clip-path: polygon(35% 9%, 67% 9%, 67% 100%, 35% 100%);
}
.sol3:hover {
    clip-path: polygon(68% 0%, 100% 0%, 100% 91%, 68% 91%);
}
.sol video {
    top: 0;
    left: 0;
    position: relative;
    margin: 0 auto;
    height: 100vh;
    width: 100vw;
    filter: brightness(80%);
    z-index: 1100;
    object-fit: cover;
}
.sol-click {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: 0.6s ease;
    z-index: 9999;
}
.sol-head {
    position: absolute;
    color: #ffffff;
    width: 25vw;
    z-index: 1199;
}
.sol-num {
    width: 5vw;
    height: 5vw;
    border-bottom: 2px solid #ffffff;
    padding: 0;
    margin: 0;
}
.sol-num > p {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4.2vw;
}
.num01 {
    position: absolute;
    left: 6%;
    top: 10%;
}
.num02 {
    position: absolute;
    left: 39%;
    top: 50%;
}
.num03 {
    position: absolute;
    left: 72%;
    top: 10%;
}
.sol-head > h1 {
    font-size: 2vw;
    font-weight: normal;
    font-family: YuMincho, 'YuMincho', serif;
}
.solpop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}
.popping {
    opacity: 0;
    transition: 0.6s ease;
    z-index: 10000;
}
.popped {
    opacity: 1;
    transition: 0.6s 0.3s ease;
    z-index: 10000;
}
.sol-desc {
    position: absolute;
    left: 6%;
    top: 33%;
    z-index: 10000;
    background-color: #00000080;
}
.sol-desc > p {
    font-size: 1.5vw;
    color: #ffffff;
    padding: 0 20px;
}
.sol-wrap {
    left: 6%;
    position: absolute;
    width: 90%;
    top: 60%;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
}
.solinner {
    width: 43%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 10000;
}
.solinner img {
    position: relative;
    width: 100%;
    box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
}
.solinner h2 {
    color: #ffffff;
    margin-top: 0;
    font-weight: normal;
    font-size: 1.5vw;
    font-family: 'Hiragino Kaku Gothic ProN', sans-serif;
    margin: 0;
}

/* #num01-img2{
  position: relative;
  height: 100%;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
} */

/* BackVideo Component */

.BackVideo {
    height: 50vh;
    position: relative;
    z-index: 900;
}

.BackVideo video {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: -1;
}

.backvideo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-weight: bold;
}
/* Member Component */
.member {
    position: relative;
    background: linear-gradient(180deg, #000000 0%, #000000 50%, #2a2a2a 50%, #2a2a2a 100%);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}
.member h1 {
    position: absolute;
    top: 5vh;
    left: calc(30vh);
    color: #ffffff;
    z-index: 1000;
    font-size: 70px;
    font-family: 'Arial Black', Helvetica, sans-serif;
}
.member-field {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 20vh;
    z-index: 1000;
    overflow: hidden;
}
.scrollsection {
    position: absolute;
    width: auto;
    height: 100vh;
    left: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.member-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: top;
    width: 80vw;
    height: 46vh;
    z-index: 1000;
    margin-right: 5vw;
}
.member-container img {
    position: relative;
    z-index: 1000;
    margin-right: 5vw;
}
.member-info {
    position: relative;
    overflow-wrap: normal;
}
.member-post-container,
.member-detail-post-container {
    position: relative;
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: left;
}
.member-detail-post-container {
    height: 20%;
}
.member-post {
    position: relative;
    text-stroke: 1px rgb(255, 255, 255);
    -webkit-text-stroke: 1px rgb(255, 255, 255);
    color: #000000;
    font-size: 4vw;
    font-family: Lexend, 'Lexend Regular', sans-serif;
    font-feature-settings: 'palt' 1;
    letter-spacing: -2px;
    display: flex;
    align-items: end;
    padding-right: 10px;
}
.member-link {
    align-items: center;
    justify-content: left;
}

.member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 250px;
    text-decoration: none;
    line-height: 60px;
    outline: none;
    color: #fff;
    position: relative;
    border: 1px solid #fff;
    transition: color 0.5s ease;
}
.member-btn:hover {
    color: #333;
}
.member-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}
.member-btn::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #171717;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    transition-property: transform;
}
.member-btn-text {
    position: relative;
}

.member-post a {
    color: unset;
}
.member-post a:first-letter {
    font-size: 6vw;
    color: #ffffff;
}
.member-name,
.member-detail-name {
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: left;
    align-items: baseline;
    margin-bottom: 1vh;
}
.name-jp,
.member-detail-name-jp {
    position: relative;
    font-size: 44px;
    margin-right: 1vw;
    font-weight: 900;
}
.name-en,
.member-detail-name-en {
    position: relative;
    font-size: 32px;
}
.member-desc,
.member-detail-desc {
    position: relative;
    color: #ffffff;
    width: 80%;
    overflow-wrap: normal;
}

.member-detail-desc {
    width: 100%;
}

/* Recruit Component */
.recruit {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #2a2a2a;
    z-index: 1000;
    overflow: hidden;
}
.recruit .text {
    position: absolute;
    height: 50vh;
    width: 70vw;

    margin: 5vh auto;
    right: 0;
    left: 0;

    z-index: 1002;
    font-size: 7vw;
    color: #ffffff;
    font-family: 'Arial Black', Helvetica, sans-serif;
    font-weight: 900;
}
.recruit .text p {
    margin-top: 10vh;
    text-align: center;
    font-size: 1.5vw;
    word-break: keep-all;
    overflow-wrap: break-word;
    font-weight: 100;
}
.recruit .text .head2 {
    display: flex;
    justify-content: right;
    align-items: right;
}
.recruit .text .head2 .head2-1 {
    text-stroke: 1px rgb(255, 255, 255);
    -webkit-text-stroke: 1px rgb(255, 255, 255);
    color: #00000000;
}
.recruit .text .head2 .head2-2 {
    margin-left: 50px;
}

.button-recruit {
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    color: #fff;
    font-size: 1.5vw;
}
.recruit .filter {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #454545;
    opacity: 0.7;
    z-index: 1001;
}
.recruit video {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

/* RecruitDetail Component */
/* PrivacyPolicy Component */
/* Benefits Component */
/* CompanyInfoと一部共通化 */

.company-info,
.recruit-detail,
.member-detail,
.benefits,
.privacy-policy {
    position: relative;
    width: 100vw;
    background: #2a2a2a;
    z-index: 1000;
    overflow: hidden;
    display: flexbox;
    justify-content: left;
}

.company-info h1,
.recruit-detail h1,
.member-detail h1,
.benefits h1,
.privacy-policy h1 {
    position: relative;
    top: 5vh;
    left: calc(30vh);
    margin: 0;
    color: #ffffff;
    z-index: 1000;
    font-size: 70px;
    font-family: 'Arial Black', Helvetica, sans-serif;
    z-index: 1000;
}

.company-info-area,
.benefits-area,
.recruit-detail-area,
.member-detail-area,
.privacy-policy-area {
    position: relative;
    width: calc(100vw - 20vh);
    height: auto;
    left: 30vh;
    margin-top: 9vh;
}

.recruit-detail-area-wrap {
    width: calc(100vw - 40vh);
    padding: 20px 0;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.recruit-detail-area-h2 {
    margin-bottom: 5px;
    margin-top: 15px;
    font-size: 35px;
}
.recruit-detail-btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0;
}
/* MemberDetail Component */
.member-detail-area-wrap {
    width: calc(100vw - 40vh);
    display: flex;
    flex-direction: column;
}

.member-detail-area-wrap div.member-detail-container:last-child {
    border: none;
    margin: 0;
}

.member-detail-container {
    display: flex;
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #dddddd55;
}
.member-detail-img {
    width: 30%;
}
.member-detail-img img {
    width: 100%;
}
.member-detail-info {
    width: 70%;
    padding-left: 50px;
}

/* Benefits Component */
.benefits {
    font-size: 24px;
}
.benefits h1 {
    top: 0;
    left: 0;
}
.benefits-area {
    height: 100vh;
    width: calc(100vw - 30vh);
    position: relative;
    margin-top: 5vh;
}
.benefits-area p {
    margin-top: 3vh;
    width: calc(100vw - 33vh);
}
.benefits-bar-wrap {
    height: 50vh;
    overflow: scroll;
}
.benefits-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -30vh;
    top: 0;
    z-index: -1;
}
.benefits-video video {
    width: 100vw;
    position: relative;
    height: 100vh;
    object-fit: cover;
    left: 0 !important;
    background: #454545;
    opacity: 0.7;
}

.benefits-contents {
    margin-top: 10vh;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.benefits-content {
    width: 35%;
}
.benefits-title {
    font-size: 2em;
    height: 2.5em;
    font-weight: 900;
    width: 100%;
}
.benefits-img-wrap {
    width: 100%;
}
.benefits-name {
    font-size: 1.3em;
    height: 1.5em;
    width: 100%;
}
.benefits-img img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}
/* CompanyInfo Component */

.company-info-midashi-wrap {
    border-bottom: 1px solid #dddddd55;
    width: calc(100vw - 40vh);
    padding: 20px 0;
    color: #fff;
    display: flex;
}
.company-info-midashi-wrap:last-child {
    border: 0;
}
.company-info-midashi {
    width: 150px;
    font-weight: bold;
}

.praivacy-end {
    margin-top: 30px;
}

/* Contact Component */
.contact {
    position: relative;
    width: 100vw;
    padding-bottom: 100px;
    background: #2a2a2a;
    z-index: 1001;
    overflow: hidden;
    display: flexbox;
    justify-content: left;
}

.contact h1 {
    position: relative;
    top: 5vh;
    left: calc(30vh);
    margin: 0;
    color: #ffffff;
    z-index: 1000;
    font-size: 70px;
    font-family: 'Arial Black', Helvetica, sans-serif;
    z-index: 1000;
}

.contactform-area,
.recruitform-area {
    position: relative;
    width: calc(100vw - 40vh);
    height: auto;
    left: 30vh;
    display: flex;
    justify-content: space-between;
    margin-top: 9vh;
}
.recruitform-area {
    left: 0;
    margin-top: 0;
}
.contactforms {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 48%;
}
.contact-input-box,
.contact-textarea-box {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}
.contact-input-box {
    height: 40px;
}
.contact-textarea-box {
    height: 100%;
}
.contact-input-box label,
.contact-textarea-box label {
    color: #737373;
    z-index: 1;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    left: 1%;
}
.contact-input-box label {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.contact-textarea-box label {
    top: 5%;
    transform: translateY(0);
}
.contact-input-box input,
.contact-textarea-box textarea {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: large;
    color: #000;
}
.contact-input-box.focused label,
.contact-textarea-box.focused label {
    color: #fff;
    left: 0;
}
.contact-input-box.focused label {
    transform: translate(-9%, -205%) scale(0.8); /* フォーカス時のラベルの移動と縮小 */
    transition: transform 0.3s ease-in-out;
}
.contact-textarea-box.focused label {
    transform: translate(-9%, -162%) scale(0.8); /* フォーカス時のラベルの移動と縮小 */
    transition: transform 0.3s ease-in-out;
}
.send {
    position: relative;
    width: calc(100vw - 40vh);
    height: auto;
    left: 30vh;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    border: 0;
    background-color: #2a2a2a;
}
.contact a {
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 0.8;
    position: relative;
    padding: 1.5rem 4rem;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #737373;
}

.contact a:hover {
    color: #000000;
    background: #ffffff;
}

.modal-overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.modal {
    /* モーダルのスタイル */
    display: flex;
    flex-direction: column;
    background: #2a2a2a;
    width: 60vw;
    height: 70vh;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    color: #fff;
    position: relative;
    justify-content: space-between;
}
.modal-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
}
.modal-title {
    color: #fff;
    font-family: Arial Black, Helvetica, sans-serif;
    font-size: 70px;
}
.modal-content {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
}
.modal-content-name,
.modal-content-text {
    height: 100%;
}
.modal-content-text {
    width: 40vw;
    overflow-wrap: break-word;
    overflow-y: scroll;
    max-height: 30vh;
}
.modal-content-name {
    font-weight: bold;
    width: 150px;
    padding-left: 1%;
}
.modal-send {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    border: 0;
    background-color: transparent;
}
.close-button {
    /* 閉じるボタンのスタイル */
    position: absolute;
    top: -10px;
    font-size: 40px;
    right: 20px;
    cursor: pointer;
}

/* Privacy Policy Component */
.privacy-policy {
    color: #fff;
}
.privacy-policy-area {
    width: calc(100vw - 35vh);
}
.privacy-midashi {
    font-size: 1.5rem;
    font-weight: bold;
}
/* Exlink Component */
.exlink {
    position: relative;
    height: 20vh;
    background: #2a2a2a;
    z-index: 1000;
}
.ex-container {
    position: relative;
    width: calc(100vw - 20vh);
    height: 100%;
    left: 20vh;
}
.ex-field {
    position: relative;
    width: calc(100vw - 40vh);
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dddddd55;
    border-bottom: 1px solid #dddddd55;
}

.ex-field a {
    width: auto;
    height: 25%;
    margin: 0 1vw;
}

.ex-field img {
    height: 100%;
    /* margin: 2rem; */
}

/* Footer Component */
.footer {
    position: relative;
    height: 9vh;
    background-color: #2a2a2a;
    z-index: 1002;
}
.footer-container {
    position: relative;
    width: calc(100vw - 20vh);
    height: 100%;
    left: 20vh;
}
.footer-field {
    position: relative;
    width: calc(100vw - 40vh);
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-field a {
    margin: 1vw;
    text-decoration: none;
    color: #ffffff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.footer-field a:hover {
    color: #737373;
}
.flowting-icon {
    position: fixed;
    bottom: 1%;
    right: 24px;
    /*矢印の動き1秒かけて永遠にループ*/
    animation: arrowmove 2s ease-in-out infinite;
}
.flowting-icon span {
    bottom: 10px;
    color: #eee;
    font-size: 1.2rem;
    left: -30px;
    letter-spacing: 0.15em;
    position: absolute;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
}
.flowting-icon:after,
.flowting-icon:before {
    background: #eee;
    bottom: 0;
    content: '';
    position: absolute;
    width: 2px;
}

.flowting-icon:before {
    height: 24px;
    right: -7px;
    transform: skewX(-31deg);
}
.flowting-icon:after {
    height: 80px;
    right: 0;
}
@keyframes arrowmove {
    0% {
        bottom: 1%;
    }
    50% {
        bottom: 3%;
    }
    100% {
        bottom: 1%;
    }
}

@keyframes color-change {
    0% {
        color: #ff8167;
    } /*変化させたい色*/
    25% {
        color: #ffa04d;
    } /*変化させたい色*/
    50% {
        color: #ff7b60;
    } /*変化させたい色*/
    75% {
        color: #ffff51;
    } /*変化させたい色*/
    90% {
        color: #52bafb;
    } /*変化させたい色*/
    100% {
        color: #ff5938;
    } /*変化させたい色*/
}

.policyAnchor {
    position: absolute;
    width: 100%;
    height: 10px;
    top: 12.8%;
    z-index: 0;
}

textarea {
    resize: none;
}

@font-face {
    font-family: 'Lexend';

    src: url('fonts/Lexend-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-VariableFont_wght.ttf');
}

/*---- Tab & SP ----*/
@media (max-width: 1024px) {
    .App {
        position: relative;
        min-height: 100vh;
        width: 100%;
        overflow: hidden;
        z-index: 0;
    }
    .container {
        position: relative;
        width: 100vw;
        min-height: 100vh;
        background: #2a2a2a;
        display: flex;
        flex-direction: column;
        z-index: -1;
        overflow: hidden;
    }
    /* .frame{
        display: flex;
        flex-direction: column;
    } */

    /* Load Component */

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #2a2a2a;
        z-index: 9999;
    }
    .load-animation {
        display: block;
        text-align: center;
        line-height: 2rem;
        font-size: 1.5rem;
        height: 40vh;
    }
    .loaded {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: #2a2a2a;
        z-index: 9999;
    }
    .letter,
    .japanese-text {
        width: 100%;
    }
    .letter {
        display: inline-block;
        margin: 0 0.25em;
        font-family: 'Courier New';
        position: relative;
        color: #ffffff;
        font-size: 1em;
    }
    .japanese-text {
        line-height: 10vw;
        margin-top: 30px;
        font-size: 4vw;
    }
    .load-img-wrap {
        text-align: left;
    }
    .load-img {
        width: 100vw;
    }
    .japanese-text-name {
        font-size: 4vw;
    }
    .japanese-text-year {
        font-size: 3vw;
    }
    .img-apper {
        display: block;
        animation: ld-fadein 1s ease 0s 1 forwards;
    }
    .japanese-text-disapper,
    .img-disapper {
        animation: none;
    }
    /* Silebar Component */

    .menusp {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: fixed;
        background: #151515dd;
        height: 100%;
        width: 40%;
        right: -40%;
        z-index: 9989;
        transition: 0.3s ease;
    }
    .menusp.active {
        right: 0;
    }

    .menusp ul {
        list-style: none;
        padding: 70px 0;
    }

    .menusp a {
        display: block;
        margin: 40px 0px;
        text-decoration: none;
        font-family: 'Menlo', 'Monaco', 'Courier New', 'monospace';
        font-size: 3vw;
        color: #ffffff;
        text-align: left;
    }
    .breadcrumbs {
        left: auto;
        width: 100vw;
    }
    /* Top Component */
    .top {
        top: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background: #00000000;
        z-index: 1;
    }
    .loop-wrapper {
        position: absolute;
        overflow: hidden;
        top: 10vh;
        width: 115vw;
        height: 30vh;
        color: rgba(255, 255, 255, 0.313);
        z-index: 11;
    }
    .follow {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        animation: loop 30s -15s linear infinite;
    }

    @keyframes loop {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    .loop-wrapper.down {
        top: 75vh;
    }
    .looptextx {
        animation: loopx 30s linear infinite;
        z-index: 11;
        font-size: 10vw;
        font-family: 'Hiragino Kaku Gothic ProN', sans-serif;
        margin: 0;
    }
    .followx {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        animation: loopx 30s -15s linear infinite;
    }
    @keyframes loopx {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
    .top-text-title {
        position: absolute;
        width: 100vw;
        text-align: center;
        vertical-align: center;
        top: 45vh;
        font-size: 5vw;
        letter-spacing: 5px;
        font-family: YuMincho, 'YuMincho', serif;
        z-index: 30;
        color: #ffffff;
    }
    .separater {
        position: absolute;
        width: 100%;
        height: 10vh;
        background: linear-gradient(180deg, #00000000 0%, #2a2a2a 100%);
        bottom: 0;
        z-index: 1001;
    }

    /* Policy component */

    .policy {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        position: relative;
        width: 100vw;
        height: 100vh;
        background: #2a2a2a;
        z-index: 1000;
    }
    .policy-title {
        top: -50%;
    }
    .policy-reload {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .policy-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .policy-container {
        position: relative;
        margin: 30px auto;
        width: 90%;
        perspective: 1000px;
        transform: translateY(-100%);
    }

    .policy-container .policy-container-title {
        position: absolute;
        top: 3.5rem;
        width: 100%;
        text-align: center;
    }

    .policy-container .policy-container-title-K {
        position: relative;
        display: inline-block;
        width: min-content;
        font-size: 160px;
    }
    .policy-container .policy-container-title-K {
        color: #fff;
        font-family: 'Lexend';
    }
    .policy-box {
        position: absolute;
        top: 90%;
        left: 15%;
        display: inline-block;
        width: 70%;
        height: 3px;
        border-radius: 50%;
        background-color: #000;
        filter: blur(5px);
    }

    /* 立方体 */
    .cube {
        position: relative;
        margin: 30px auto;
        width: 380px;
        height: 200px;
        transform-style: preserve-3d;
        animation: rotate 30s linear infinite;
    }
    ul.cube {
        list-style: none;
    }
    @keyframes rotate {
        from {
            transform: rotateX(-10deg) rotateY(20deg);
        }
        to {
            transform: rotateX(-10deg) rotateY(-340deg);
        }
    }

    .cube li {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #2e2e2e70;
    }

    .policy-top {
        transform: translate3d(0, -200px, 0) rotateX(90deg);
        background-color: transparent !important;
    }

    .policy-front {
        transform: translate3d(0, 0, -200px);
    }

    .policy-right {
        transform: translate3d(-200px, 0, 0) rotateY(90deg);
    }

    .policy-back {
        transform: translate3d(0px, 0px, 200px) rotateY(180deg);
    }

    .policy-left {
        transform: translate3d(200px, 0, 0) rotateY(-90deg);
    }
    .policy-text h1 {
        font-size: 26px;
    }
    .policy-text-box {
        position: relative;
        transform: scaleX(-1);
    }
    .policy-text-left {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .policy-text-box div {
        position: absolute;
        top: 0%;
        padding: 5% 5%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 380px;
        height: 200px;
        font-size: 16px;
        /*background-color: #2a2a2a90;*/
        z-index: -1;
        /*transform: rotateY(-180deg);*/
    }
    @keyframes glowingAnimation {
        0% {
            text-shadow: 0 0 5px #ffffff80; /* 開始時の発光 */
        }
        50% {
            text-shadow: 0 0 20px #ffffff; /* 明滅中の強い発光 */
        }
        100% {
            text-shadow: 0 0 5px #ffffff80; /* 開始時の発光 */
        }
    }

    .blinking-glow {
        color: #fff; /* テキストの色 */
        animation: glowingAnimation 3s infinite; /* 2秒かけてアニメーションし、無限に繰り返す */
    }

    /**********************/
    /* Solution Component */
    /**********************/

    .solution,
    .solution-open {
        position: relative;
        width: 100%;
        height: 100vh;
        /* background: #2a2a2a; */
        background: #2a2a2a;
        z-index: 1000;
    }
    .solution-open {
        height: 198vh;
    }
    .sol-container {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0vh;
        right: 0;
        margin-right: 0px;
        display: flex;
        justify-content: space-around;
        z-index: 1100;
    }
    .solution-container {
        height: 22%;
    }

    .sol {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        opacity: 0;
        z-index: 1200;
        top: 0;
        left: 0;
        bottom: 0;
    }
    .sol1 {
        position: absolute;
        clip-path: polygon(3% 0%, 33% 0%, 33% 90%, 3% 90%);
        transition: 0.6s ease;
    }
    .sol2 {
        position: absolute;
        clip-path: polygon(36% 10%, 66% 10%, 66% 100%, 36% 100%);
        transition: 0.6s ease;
    }
    .sol3 {
        clip-path: polygon(69% 0%, 99% 0%, 99% 90%, 69% 90%);
        transition: 0.6s ease;
    }
    .sol1:hover {
        clip-path: polygon(2% 0%, 34% 0%, 34% 91%, 2% 91%);
    }
    .sol2:hover {
        clip-path: polygon(35% 9%, 67% 9%, 67% 100%, 35% 100%);
    }
    .sol3:hover {
        clip-path: polygon(68% 0%, 100% 0%, 100% 91%, 68% 91%);
    }
    .sol video {
        top: 0;
        left: 0;
        position: relative;
        margin: 0 auto;
        /* height: 100vh; */
        /* width: 100%; */
        height: 100vh;
        width: 100vw;
        filter: brightness(80%);
        z-index: 1100;
        object-fit: cover;
    }
    .sol-click {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transition: 0.6s ease;
        z-index: 9999;
    }
    .sol-head {
        position: absolute;
        color: #ffffff;
        width: 25vw;
        z-index: 1199;
    }
    .sol-num {
        width: 5vw;
        height: 5vw;
        border-bottom: 2px solid #ffffff;
        padding: 0;
        margin: 0;
    }
    .sol-num > p {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 4.2vw;
    }
    .num01 {
        position: absolute;
        left: 6%;
        top: 10%;
    }
    .num02 {
        position: absolute;
        left: 39%;
        top: 50%;
    }
    .num03 {
        position: absolute;
        left: 72%;
        top: 10%;
    }
    .sol-head > h1 {
        font-size: 2vw;
        font-weight: normal;
        font-family: YuMincho, 'YuMincho', serif;
    }
    .solpop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
    }
    .popping {
        opacity: 0;
        transition: 0.6s ease;
        z-index: 10000;
    }
    .popped {
        opacity: 1;
        transition: 0.6s 0.3s ease;
        z-index: 10000;
    }
    .sol-desc {
        position: absolute;
        left: 6%;
        top: 40%;
        z-index: 10000;
        background-color: #00000080;
    }
    .sol-desc > p {
        font-size: 1.5vw;
        color: #ffffff;
    }
    .solinner {
        position: absolute;
        height: 25vh;
        width: 40vw;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 10000;
    }
    .sol1-1 {
        top: 70%;
        left: 10%;
    }
    .sol1-2 {
        top: 70%;
        left: 55%;
    }
    .solinner > h2 {
        color: #ffffff;
        margin-top: 0;
        font-weight: normal;
        font-size: 1.5vw;
        font-family: 'Hiragino Kaku Gothic ProN', sans-serif;
    }
    .solinner > img {
        position: relative;
        height: 100%;
        box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
        margin-left: 20px;
    }
    /* #num01-img2{
      position: relative;
      height: 100%;
      box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
    } */
    .solution-contents {
        height: calc(100vh - 150px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .solution video,
    .solution-open video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    #solution-video1 {
        margin-top: 0;
    }
    #solution-video2 {
        margin-top: 0;
    }
    .solution-container,
    .solution-container-open {
        color: #fff;
        position: relative;
        width: 100%;
        height: 26vh;
        border: 1px solid #000;
        transition: 0.3s;
        padding: 0;
    }

    .solution-container-open {
        height: 100%;
    }
    .solution-video-inner-2 {
        justify-content: flex-end;
    }
    .solution-container-wrap {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .solution-container-h2-wrap,
    .solution-container-h2-wrap-open {
        position: absolute;
        top: 10px;
        left: 20px;
    }
    .solution-container-h2-wrap-open,
    .solution-container-h2-open,
    .solution-container-title-open {
        color: #2a2a2a;
        transition: 0.3s;
    }
    .solution-container-h2,
    .solution-container-h2-open {
        font-size: 60px;
        text-align: initial;
    }
    .solution-container-h2-line,
    .solution-container-h2-line-open {
        width: 60px;
        border-bottom: 1px solid #fff;
    }
    .solution-container-h2-line-open {
        border-bottom: 1px solid #2a2a2a;
        transition: 0.3s;
    }
    .solution-container-title,
    .solution-container-title-open {
        font-size: 28px;
        margin-top: 10px;
        font-family: serif;
    }
    .solution-container-text-wrap,
    .solution-container-text-wrap-open {
        position: absolute;
        top: 200px;
        left: 20px;
        background-color: #00000080;
        padding: 10px 10px;
        width: calc(100% - 40px);
        display: none;
        text-align: initial;
        height: auto;
        max-height: 55vh;
        overflow-y: scroll;
    }
    .solution-container-text-wrap-open {
        transition: 0.3s;
        display: block;
    }
    .solution-container-text {
        color: #fff;
    }
    .solution-container-text-link-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .solution-container-text-link {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .solution-container-text-link-title {
        font-size: 1.3rem;
    }
    .solution-container-text-link-img {
        width: 100%;
    }
    .solution-container-text-link-img img {
        width: 100%;
    }

    /* Member Component */
    .member {
        position: relative;
        background: linear-gradient(180deg, #000000 0%, #000000 50vh, #2a2a2a 50%, #2a2a2a 100%);
        width: 100vw;
        height: 100vh;
        z-index: 1000;
    }
    h1.title {
        position: relative;
        color: #ffffff;
        z-index: 1000;
        font-size: 4rem;
        font-family: 'Arial Black', Helvetica, sans-serif;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        left: 0;
        top: 0;
        height: 150px;
    }
    .member-field {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0vh;
        z-index: 1000;
        overflow: hidden;
    }
    .scrollsection {
        position: absolute;
        width: auto;
        height: 100%;
        left: 0vh;
        margin-left: 100px;
        display: flex;
        align-items: flex-start;
        z-index: 1000;
    }
    .member-container {
        width: 90vw;
        height: auto;
        margin-right: 200px;
    }
    .member-container img {
        position: absolute;
        width: 80vw;
        max-width: 500px;
        left: 0;
        z-index: 0;
    }
    .member-info {
        position: absolute;
        left: 20vw;
        top: 40vh;
        width: auto;
    }
    .member-link {
        height: 50vh;
        margin-top: -115px;
        align-items: flex-end;
    }
    .member-post-container {
        background-color: #33333390;
        height: auto;
    }
    .member-post {
        position: relative;
        -webkit-text-stroke: 1px rgb(255, 255, 255);
        color: #000000;
        font-size: 5vw;
        font-family: Lexend, 'Lexend Regular', sans-serif;
        font-feature-settings: 'palt' 1;
        letter-spacing: -2px;
        display: flex;
    }
    .member-post a {
        font-size: 2rem;
    }
    .member-post a:first-letter {
        font-size: 3.5rem;
        color: #ffffff;
    }
    .member-name {
        margin-bottom: 1vh;
        background-color: #33333390;
        padding: 0 10px;
    }
    .name-jp {
        position: relative;
        font-size: 1.8rem;
        margin-right: 20px;
        font-weight: 900;
    }
    .name-en {
        position: relative;
        font-size: 1.5rem;
    }
    .member-desc {
        position: relative;
        color: #ffffff;
        overflow-wrap: normal;
        padding: 0 10px;
        max-width: 600px;
        width: auto;
    }

    /* Recruit Component */
    .recruit {
        position: relative;
        width: 100vw;
        height: 100vh;
        background: #2a2a2a;
        z-index: 1000;
        overflow: hidden;
    }
    .recruit .text {
        position: absolute;
        height: 50vh;
        width: 85vw;

        margin: 5vh auto;
        right: 0;
        left: 0;
        top: 5vh;

        z-index: 1002;
        font-size: 11vw;
        color: #ffffff;
        font-family: 'Arial Black', Helvetica, sans-serif;
    }
    .recruit-head {
        font-size: 4rem;
    }
    .recruit .text p {
        text-align: center;
        font-size: 20px;
    }
    .recruit .text .head2 {
        display: flex;
        justify-content: right;
        align-items: right;
    }
    .recruit .text .head2-1 {
        text-stroke: 1px rgb(255, 255, 255);
        -webkit-text-stroke: 1px rgb(255, 255, 255);
        color: #00000000;
    }
    .head2-1 {
        left: 30%;
        position: relative;
    }
    .head2-2 {
        text-align: right;
    }
    .button-recruit {
        font-size: 4vw;
    }
    .recruit .filter {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: #454545;
        opacity: 0.7;
        z-index: 1001;
    }

    /* RecruitDetail Component */
    /* privacy-policy Component */
    /* member-detail Component */
    /* Benefits Component */
    /* CompanyInfoと一部共通化している */
    .company-info,
    .recruit-detail,
    .member-detail,
    .benefits,
    .privacy-policy {
        width: 100vw;
        height: auto;
        padding-bottom: 70px;
    }
    .company-info-area,
    .benefits-area,
    .recruit-detail-area,
    .member-detail-area,
    .privacy-policy-area {
        width: 100%;
        height: auto;
        left: auto;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
    .company-info h1,
    .benefits h1,
    .member-detail h1,
    .recruit-detail h1,
    .privacy-policy h1 {
        font-size: 65px;
        line-height: 1.1;
        left: auto;
    }
    .recruit-detail-area-h2 {
        font-size: 25px;
    }
    .company-info-midashi,
    .member-detail-midashi {
        width: auto;
        margin-bottom: 15px;
    }

    /* RecruitDetail Component */
    .recruit-detail-area-wrap,
    .member-detail-area-wrap {
        width: 80%;
        margin-bottom: 0;
    }

    /* MemberDetail Component */
    .member-detail-area-wrap {
        width: 90%;
    }

    .member-detail-area-wrap div.member-detail-container:last-child {
        border: none;
        margin: 0;
    }

    .member-detail-info {
        background-color: #33333390;
        padding: 2vw;
        left: 2vw;
        bottom: 20vw;
        position: absolute;
        width: 80%;
    }
    .member-detail-container {
        display: flex;
        margin-bottom: 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid #dddddd55;
        position: relative;
    }
    .member-detail-img {
        width: 100%;
    }

    /* CompanyInfo Component */

    .company-info-midashi-wrap {
        width: 80%;
        flex-direction: column;
    }

    /* Benefits Component */
    .benefits {
    }
    .benefits h1 {
        width: 100%;
    }
    .benefits-area {
        width: 100vw;
        height: 100vh;
        position: relative;
        margin-top: 5vh;
    }
    .benefits-area p {
        margin-top: 3vh;
        width: 100%;
    }
    .benefits-video {
        left: 0;
    }
    .benefits-video video {
        width: auto;
    }

    .benefits-contents {
        margin-top: 10vh;
        display: flex;
        width: 100%;
        justify-content: space-around;
    }
    .benefits-content {
        width: 35%;
    }
    .benefits-title {
        font-size: 2em;
        height: 2.5em;
        font-weight: 900;
        width: 100%;
    }
    .benefits-img-wrap {
        width: 100%;
    }
    .benefits-name {
        font-size: 1.3em;
        width: 100%;
    }
    .benefits-img img {
        width: 100%;
    }
    /* Contact Component */
    .contact {
        position: relative;
        width: 100vw;
        height: auto;
        background: #2a2a2a;
        overflow: hidden;
        padding-bottom: 70px;
        z-index: 1001;
    }
    .contact h1 {
        left: auto;
        top: auto;
    }
    .contact-form,
    .recruit-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactform-area {
        position: relative;
        width: 70vw;
        height: auto;
        left: 0vh;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-top: 30px;
    }
    .contactforms {
        width: 100%;
    }
    .contactforms-textarea {
        height: 150px;
    }
    input,
    textarea {
        position: relative;
        width: 100%;
        font-size: large;
    }
    .send {
        position: relative;
        width: auto;
        height: auto;
        left: auto;
        display: flex;
        margin-top: 10px;
        justify-content: center;
    }
    .contact a {
        font-size: 1.2rem;
        font-weight: 800;
        line-height: 1.2;
        position: relative;
        padding: 1rem 3rem;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        text-align: center;
        letter-spacing: 0.1em;
        color: #fff;
        background-color: #737373;
    }

    .contact a:hover {
        color: #000000;
        background: #ffffff;
    }
    .modal {
        height: 80vh;
        width: 90vw;
    }
    .modal-title {
        font-size: 10vw;
    }
    .close-button {
        font-size: 10vw;
        right: 0;
        top: -10px;
    }
    .modal-content-name {
        padding-left: 0;
        width: 100%;
        font-size: 24px;
        padding: 2% 0;
        border-bottom: 1px solid #dddddd55;
        height: auto;
    }
    .modal-content-wrap {
        width: 100%;
        height: 53vh;
        overflow-y: scroll;
    }
    .modal-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        margin-top: 20px;
    }
    .modal-content-text {
        width: 100%;
        margin-top: 5px;
    }
    /* Exlink Component */
    .exlink {
        position: relative;
        height: 20vh;
        background: #2a2a2a;
        z-index: 1000;
        padding-top: 30px;
    }
    .ex-container {
        position: relative;
        width: calc(100vw - 20vh);
        height: 100%;
        left: 0vh;
        margin: auto;
    }
    .ex-field {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dddddd55;
        border-bottom: 1px solid #dddddd55;
    }

    .ex-field a {
        margin: 0 1vw;
        height: auto;
    }

    .ex-field img {
        height: 30px;
        margin: 1rem;
    }

    /* Footer Component */
    .footer {
        padding-top: 30px;
        position: relative;
        height: auto;
        background-color: #2a2a2a;
        z-index: 1000;
        padding: 30px 0 20px;
    }
    .footer-container {
        position: relative;
        width: 100vw;
        height: 100%;
        left: 0vh;
    }
    .footer-field {
        position: relative;
        width: 100vw;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
    .footer-field a {
        margin: 0 3vw;
    }

    .flowting-icon {
        right: 17px;
    }
    .flowting-icon span {
        font-size: 0.7rem;
        left: -18px;
    }
    .flowting-icon:before {
        height: 15px;
        right: -5px;
    }
    .flowting-icon:after {
        height: 50px;
    }
    .right-item {
        margin-top: 20px;
    }

    .policyAnchor {
        position: absolute;
        width: 100%;
        height: 10px;
        top: 12.8%;
        z-index: 0;
    }

    textarea {
        resize: none;
    }

    @font-face {
        font-family: 'Lexend';

        src: url('fonts/Lexend-VariableFont_wght.ttf') format('truetype');
    }
    @font-face {
        font-family: 'Rubik';
        src: url('fonts/Rubik-VariableFont_wght.ttf');
    }
    .accordion {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 1rem;
    }

    .accordion-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .accordion-title h3 {
        margin: 0;
        font-weight: normal;
    }

    .accordion-title span {
        font-size: 1.5rem;
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
        padding: 0 1rem;
    }

    .accordion-content p {
        margin: 0;
        padding: 1rem 0;
        color: #ffffff;
    }

    /* Ham menu */
    .han-menu-wrap nav {
        display: flex;
        justify-content: space-between;
        position: fixed;
        width: 100vw;
        height: 15vw;
        background: #00000000;
        z-index: 9990;
    }
    .han-menu-wrap .logo {
        height: 70px;
        width: auto;
    }
    .han-menu-wrap .logo h1 {
        font-size: 36px;
    }
    .ham-menu {
        position: relative;
        float: right;
        width: 15vw;
        height: 15vw;
    }
    .ham-menu span {
        display: block;
        height: 3px;
        width: 40%;
        background: #ffffff;
        border-radius: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s ease;
    }
    .ham-menu span:nth-child(1) {
        top: 35%;
    }
    .ham-menu span:nth-child(3) {
        top: 65%;
    }
    .ham-menu.active span:nth-child(1) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    .ham-menu.active span:nth-child(2) {
        opacity: 0;
    }
    .ham-menu.active span:nth-child(3) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
@media (max-width: 600px) {
    .solution-container-h2,
    .solution-container-h2-open {
        font-size: 2rem;
    }
    .solution-container-h2-line,
    .solution-container-h2-line-open {
        width: 3rem;
    }
    .recruit-head {
        font-size: 3rem;
    }
    .solution-container-title,
    .solution-container-title-open {
        font-size: 1.2rem;
    }
    .head2-1 {
        left: 23%;
    }
    .member-link {
        height: 50vh;
        margin-top: -65px;
        align-items: flex-end;
    }
}
/* for SP */
@media (max-width: 480px) {
    .top-text-title {
        font-size: 8vw;
    }
    .fullscreen-video {
        height: 100vh;
    }
    h1.title {
        font-size: 2.3rem;
        height: 100px;
    }
    .policy-container .policy-container-title-K {
        font-size: 120px;
    }
    .policy-container {
        transform: translateY(-50%);
    }
    .policy-text-box div {
        font-size: 14px;
        width: 250px;
    }
    .policy-text h1 {
        font-size: 20px;
    }
    .policy-top {
        transform: translate3d(0, -150px, 0) rotateX(90deg);
    }

    .policy-front {
        transform: translate3d(0, 0, -150px);
    }

    .policy-right {
        transform: translate3d(-150px, 0, 0) rotateY(90deg);
    }

    .policy-back {
        transform: translate3d(0px, 0px, 150px) rotateY(180deg);
    }

    .policy-left {
        transform: translate3d(150px, 0, 0) rotateY(-90deg);
    }
    .cube {
        width: 250px;
    }
    .solution-contents {
        height: calc(100vh - 100px);
    }
    .solution-container {
        height: 23vh;
    }
    .solution-container-open {
        height: 100%;
    }
    .solution-container-text-wrap,
    .solution-container-text-wrap-open {
        top: 120px;
    }

    .member-detail {
        padding: 0;
    }

    .member-detail-area-wrap div.member-detail-container:last-child {
        border: none;
        margin: 0;
    }

    .member-detail-container {
        padding-bottom: 150px;
    }

    .member-info,
    .member-detail-info {
        width: auto;
        bottom: 0;
        left: -7vw;
        font-size: 15px;
    }

    .member-detail-info {
        bottom: 15vw;
    }

    .member-post a {
        font-size: 8vw;
    }

    .member-post a:first-letter {
        font-size: 11vw;
    }

    .member-detail-name-jp {
        font-size: 32px;
    }

    .member-detail-name-en {
        font-size: 24px;
        margin-left: 15px;
    }

    .member-detail-desc {
        min-width: 340px;
        font-size: 14px;
    }

    .head2-1 {
        left: 18%;
    }
    .benefits-contents {
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        height: 50vh;
        overflow: scroll;
    }
    .benefits-content {
        width: 100%;
    }
    .benefits-bar-wrap {
        height: auto;
        overflow: scroll;
    }
}
